<template>
  <FocusTrap>
    <div class="card">

      <div class="card-header header-elements-inline" style="background-color: whitesmoke; padding: 10px;">
        <h5 v-if="voucher.id == 0" class="card-title">Contra Creation</h5>
        <h5 v-if="voucher.id > 0" class="card-title"> Doc No: {{voucher.vch_no}}  Contra Updation</h5>
        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="reload" @click="loadData" ></a>
            <a class="list-icons-item" data-action="remove" @click="closeThis"></a>
          </div>
        </div>
      </div>



      <div class="card-body">

        <div class="row">

          <div class="col-md-3">
            <div class="form-group form-group-material">
              <label class="control-label text-semibold">From Ledger</label>
              <select  ref="cmbledger" id="cmbledger" class="form-control select" autofocus="" required="" v-if="voucher" v-model="voucher.ledger_id" @change="paid_ledger_changed" >
                <option v-for="ledger in ledgers" v-bind:value="ledger.id">
                  {{ ledger.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-7">

          </div>

          <div class="col-md-2">
            <div class="form-group form-group-material">
              <label class="control-label text-semibold">Doc Date</label>
              <input id="txtDate" type="date" class="form-control" v-if="voucher" v-model="voucher.doc_date">
            </div>
          </div>
        </div>

        <div class="row">
          <!--  Detail Table -->
          <div class="table-responsive">

            <table id="mytable" class="table table-no-bordered">
              <thead style="background-color: lightgrey">
              <tr>
                <th style="width:50px;">S.No</th>
                <th >Particulars</th>
                <th >Description</th>
                <th style="width:150px; text-align: right;">Amount</th>
                <th style="width: 30px;">Action</th>
              </tr>

              </thead>
              <tbody >
                <tr v-for="(detail,index) in voucher.list" >
                <td style="text-align: center;padding: 0px;"> {{index + 1}} </td>
                <td style="padding: 0px;">
                  <select class="form-control" v-if="detail" v-model="detail.ledger_id" >
                    <option v-for="ledger in deailledgers" v-bind:value="ledger.id">
                      {{ ledger.name }}
                    </option>
                  </select>
                </td>
                <td style="padding: 0px;">
                  <input type="text" class="form-control" placeholder="Description" maxlength="100"  v-if="detail" v-model="detail.remarks" />
                </td>
                <td style="padding: 0px;">
                  <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.credit" @change="sumRowAmountTotal" />
                </td>
                <td style="padding: 0px;text-align: right">
                  <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td style="padding: 0px;">
                  <button id="btnAddRow" type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addRow">
                    <i class="icon-plus3"></i>
                  </button>
                </td>
                <td></td>
                <td></td>

                <td class="text-right" >&#8377;<span style="padding-right: 15px;">{{ indianFormat(rowAmountTotal) }}</span></td>

                <td></td>


              </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <!-- / Detail Table -->

        <div class="row align-items-end">
          <div class="col-md-3">
            <div class="form-group form-group-material">
              <label class="control-label text-semibold">Narration</label>
              <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="voucher" v-model="voucher.remarks" > </textarea>
            </div>
          </div>


          <div class="col-md-6">
          </div>

          <div class="col-md-3 text-right">
            <button id="btnSave" type="button" class="btn bg-green-800 btn-raised active" @click="saveVoucher">Save</button>
            <button type="button" id="btnclose" class="btn btn-default" data-dismiss="" @click="clear" >Clear</button>
          </div>
        </div>
      </div>


    </div>
  </FocusTrap>
</template>

<script>
import moment from 'moment-timezone'
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'
export default {
  name: 'ContraForm',
  store,
  components:{

  },
  data () {
    return {
      readonly: false,
      ledgers:[],
      deailledgers:[],
      rowAmountTotal: 0.0,
      voucher: JSON.parse('{"id":0,"status":"A","type":0,"vch_no":0,"finyear":0,"vch_type":0,"doc_date":"2000-01-01","doc_no":"","ref_date":"2000-01-01","ref_no":"","amount":0,"ledger_id":1,"remarks":"","voucher_type":{"id":1,"type":0,"status":"A","name":""},"ledger":{"id":1,"group_id":0,"status":"A","type":0,"name":"","alias":"","open_bal":0,"group":{"id":0,"name":"","alias":"","parent_id":0,"nature":2,"is_profit":false,"is_subledger":false,"is_net_bal":false,"status":"A","type":0}},"list":[]}')
    }
  },
  props: {
    myvoucher: {
      type: Object,
      default: () => JSON.parse('{"id":0,"status":"A","type":0,"vch_no":0,"finyear":0,"vch_type":0,"doc_date":"2000-01-01","doc_no":"","ref_date":"2000-01-01","ref_no":"","amount":0,"ledger_id":1,"remarks":"","voucher_type":{"id":1,"type":0,"status":"A","name":""},"ledger":{"id":1,"group_id":0,"status":"A","type":0,"name":"","alias":"","open_bal":0,"group":{"id":0,"name":"","alias":"","parent_id":0,"nature":2,"is_profit":false,"is_subledger":false,"is_net_bal":false,"status":"A","type":0}},"list":[]}')
    }
  },
  created () {
    const self = this;
    this.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
    this.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
    self.loadData();
  },
  beforeMount () {
    const self = this;

    try {
      let id = parseInt(self.myvoucher.ledger_id);
      //self.$data.deailledgers = [];
      self.$data.ledgers.forEach( (ldgr)=> {
        if(ldgr.id != id) {
          self.$data.deailledgers.push(ldgr);
        }
      });
      this.voucher = this.myvoucher;
      this.sumRowAmountTotal();
    }catch (e) {
      alert(e);
    }
  },


  mounted () {
    const self = this;
    // self.loadData();

    if( self.$data.voucher.doc_date == '0001-01-01' || self.$data.voucher.doc_date == '2000-01-01' ){
      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
    }

    $('#cmbledger').focus();
  },
  methods:{
    indianFormat(val){
      return parseFloat(val).toFixed(2);
    },
    closeThis(){
      this.$emit('contra_window_closed');
      if(this.voucher.id > 1){
        this.$modal.hide('voucher-window');
        this.voucher = JSON.parse('{"id":0,"status":"A","type":0,"vch_no":0,"finyear":0,"vch_type":0,"doc_date":"2000-01-01","doc_no":"","ref_date":"2000-01-01","ref_no":"","amount":0,"ledger_id":1,"remarks":"","voucher_type":{"id":1,"type":0,"status":"A","name":""},"ledger":{"id":1,"group_id":0,"status":"A","type":0,"name":"","alias":"","open_bal":0,"group":{"id":0,"name":"","alias":"","parent_id":0,"nature":2,"is_profit":false,"is_subledger":false,"is_net_bal":false,"status":"A","type":0}},"list":[]}');
      }else{
        this.$router.push("/")
      }
    },
    addRow(){
      const self = this;
      self.$data.voucher.list.push(JSON.parse('{"id":0,"voucher_id":0,"idx":0,"adjust_type":0,"ledger_id":0,"debit":0,"credit":0,"remarks":"","ledger":{"id":0,"group_id":0,"name":"","alias":"","open_bal":0}}'));

      setTimeout(function(){
        $("tbody>tr").last().find("td:eq(1) select").focus();
      }, 99);

    },
    sumRowAmountTotal() {
      const self = this;

      self.$data.rowAmountTotal = 0.0;
      self.$data.voucher.list.forEach(function (detail) {
        self.$data.rowAmountTotal += parseFloat(detail.credit);
      });
    },
    clear(){
      const self = this;
      self.$data.voucher = JSON.parse('{"id":0,"status":"A","type":0,"vch_no":0,"finyear":0,"vch_type":0,"doc_date":"2000-01-01","doc_no":"","ref_date":"2000-01-01","ref_no":"","amount":0,"ledger_id":1,"remarks":"","voucher_type":{"id":1,"type":0,"status":"A","name":""},"ledger":{"id":1,"group_id":0,"status":"A","type":0,"name":"","alias":"","open_bal":0,"group":{"id":0,"name":"","alias":"","parent_id":0,"nature":2,"is_profit":false,"is_subledger":false,"is_net_bal":false,"status":"A","type":0}},"list":[]}');
      self.$data.voucher.list = [];
      self.$data.rowAmountTotal =0.0;
      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');

    },
    loadData() {
      const self = this;

      const requestOptions = {
        mode:'cors',
        headers: userService.authHeader()
      };

      //fetch the Ledgers
      self.$data.ledgers = [];
      self.$data.deailledgers = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/cash_bank_ledgers`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok){
          if(_.isArray(resp.data)){
            self.$data.ledgers = resp.data;

            try {
              let id = parseInt(self.myvoucher.ledger_id);
              self.$data.ledgers.forEach( (ldgr)=> {
                if(ldgr.id != id) {
                  self.$data.deailledgers.push(ldgr);
                }
              });
            }catch (e) {
              alert(e);
            }
          }
        }else{
          swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
        }
      }).catch(function (err) {
        swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
      });

    },
    removeRow(index){
      if (index > -1) {
        this.$data.voucher.list.splice(index, 1);
        this.sumRowAmountTotal();
      }
    },
    saveVoucher(){
      const self = this;
      try {

        if(self.$data.voucher.ledger_id <= 1) {
          alert("Invalid From Ledger");
          return;
        }else if(self.$data.voucher.list.length == 0 ) {
          alert("Invalid Breakups");
          return;
        }else {
          let total = 0;
          self.$data.voucher.list.forEach(function (detail) {
            if(detail.ledger_id <= 1 ) {
              alert("Invalid Breakup Ledger");
              return;
            }else if( (parseFloat(detail.debit) + parseFloat(detail.credit)) <= 0) {
              alert("Invalid Amount")
              return;
            }
            total +=  parseFloat(detail.debit) + parseFloat(detail.credit);
          });
          if(total !== parseFloat(self.$data.rowAmountTotal)) {
            alert("Invalid Voucher Amount");
            return;
          }

        }

        let myvoucher =  JSON.parse(JSON.stringify(self.$data.voucher))

        myvoucher.finyear = self.$store.state.user.finyear;
        myvoucher.cmp_id = self.$store.state.user.company.id;
        myvoucher.brn_id = self.$store.state.user.branch.id;
        myvoucher.vch_type = 104;
        myvoucher.iscredit = false;
        myvoucher.type = 2; //Debit Transaction
        myvoucher.ledger_id = parseInt(myvoucher.ledger_id);
        myvoucher.doc_date = moment(myvoucher.doc_date).format('YYYY-MM-DD');
        myvoucher.ref_date = moment(myvoucher.doc_date).format('YYYY-MM-DD');
        myvoucher.amount = parseFloat(self.$data.rowAmountTotal);

        myvoucher.list.forEach(function (detail) {
          detail.ledger_id = parseInt(detail.ledger_id);
          detail.debit = parseFloat(detail.debit);
          detail.credit = parseFloat(detail.credit);
        });

        console.log(JSON.stringify(myvoucher));

        const requestOptions = {
          method: ((myvoucher.id == 0) ? 'POST' : 'PUT'),
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(myvoucher)
        };

        fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/simplevoucher`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            swal({ type: 'success', text: resp.msg, onClose: () => { $('#cmbledger').focus() }, timer: 1500 });
            self.clear();
            self.$emit('contra_updated');
          } else {
            swal({
              title: "Oops", text: resp.msg, type: "error", onClose: () => {
                $('#cmbledger').focus()
              }, timer: 3000
            });
          }
        }).catch(function (err) {
          swal({
            title: "Oops", text: err.toString(), type: "error", onClose: () => {
              $('#cmbledger').focus()
            }, timer: 3000
          });
        });

      }catch (e) {
        alert(e);
      }
    },
    paid_ledger_changed(){
      const self = this;
      try {
        let id = parseInt(self.$data.voucher.ledger_id);
        self.$data.deailledgers = [];
        self.$data.ledgers.forEach( (ldgr)=> {
          if(ldgr.id != id) {
            self.$data.deailledgers.push(ldgr);
          }
        });
      }catch (e) {
        alert(e);
      }
    },
  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
